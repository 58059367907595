import { Link } from 'react-router-dom';
import React from 'react';
import BGImgMain from '@assets/img/bg/bdrc-bg.jpg';
import { connect } from 'react-redux';
import { fullBackendPath } from '@core/helpers';
import { useTranslation } from 'react-i18next';

const ErrorHandleView = (props) => {
  const { t, i18n } = useTranslation();
  const { intro_section } = props;
  const { intro_image1 } = intro_section;

  return (
    <main>
      <section
        className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
        style={{
          background: `url("${fullBackendPath(intro_image1?.path)}")`,
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>Something went wrong</h2>
                  <div className="breadcrumb-wrap">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-area pt-113 pb-150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="section-title center-align mb-50 text-center">
                <h3>{t('We are sorry')}</h3>
                <h5 style={{ textTransform: 'unset' }}>
                  {t(
                    "Something went wrong with this page, so it can't display, we'll fix it as soon as possible"
                  )}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="newslater-area p-relative pt-120 pb-120"
        style={{ background: '#f7f5f1' }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-xl-9 col-lg-9">
              <div
                className="section-title center-align mb-40 text-center wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>(t{'Newsletter'})</h5>
                <h2>{t('Get Best Offers On The Hotel')}</h2>
                <p>
                  {t(
                    'With the subscription, enjoy your favourite Hotels without having to think about it'
                  )}
                </p>
              </div>
              <form
                name="ajax-form"
                id="contact-form4"
                action="#"
                method="post"
                className="contact-form newslater"
              >
                <div className="form-group">
                  <input
                    className="form-control"
                    id="email3"
                    name="email"
                    type="email"
                    placeholder={t('Your Email Address')}
                    required=""
                  />
                  <button type="submit" className="btn btn-custom" id="send2">
                    {t('Subscribe Now')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = (state) => ({
  intro_section: state.home.intro_section,
});

export default connect(mapStateToProps, {})(ErrorHandleView);
