import { Link } from 'react-router-dom';
import React from 'react';
import Slider from 'react-slick';
import BGTestimonial from '@assets/img/bg/testimonial-bg.png';
import { connect } from 'react-redux';
import Aicon from '@assets/img/testimonial/qt-icon.png';
import Aicon1 from '@assets/img/testimonial/review-icon.png';
import avatar1 from '@assets/img/testimonial/testi_avatar.png';
import { fullBackendPath, kebabCase } from '@core/helpers';
import ErrorBoundary from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

const Services = (props) => {
  const { t, i18n } = useTranslation();
  const { intro_section, section3, comments, services } = props;
  const { intro_image1 } = intro_section;
  const { describe: section3Describe, sec3_image } = section3;

  const testimonial = {
    initialIndex: 2,
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    autoplay: true,
    slidesToShow: 3,
  };

  const testimonialData =
    comments?.map((cm) => {
      const { id, avatar, comment, first_name, last_name, star } = cm;

      return (
        <div key={id} className="single-testimonial">
          <div className="testi-author">
            <img
              className="img-responsive avatar-comment"
              src={fullBackendPath(avatar.path)}
              alt="img"
            />
            <div className="ta-info">
              <h6>{`${first_name} ${last_name}`}</h6>
              <span>Client</span>
            </div>
          </div>
          <div className="review-icon">
            <img src={Aicon1} alt="img" />
          </div>
          <p>{comment}</p>
          <div className="qt-img">
            <img src={Aicon} alt="img" />
          </div>
        </div>
      );
    }) || [];

  const onClickCheckAvailability = () => {
    const checkin = document.getElementById('checkin').value;
    const checkout = document.getElementById('checkout').value;
    const adults = document.getElementById('adults').value;
    const children = document.getElementById('children').value;
    const bookLink = `https://book-directonline.com/properties/anhillboutiquedirect?locale=en&items[0][adults]=${adults}&items[0][children]=${children}&items[0][infants]=0&currency=VND&checkInDate=${checkin}&checkOutDate=${checkout}`;
    window.open(bookLink, '_blank').focus();
  };

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            // background: `url("${fullBackendPath(intro_image1?.path)}")`,
            background: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title">
                    <h2>Services</h2>
                    {/* <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Services
                          </li>
                        </ol>
                      </nav>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="service-details2" className="pt-120 pb-90 p-relative">
          <div className="container">
            <div className="row align-items-center">
              {services.map((service) => {
                return (
                  <div key={service.id} className="col-xl-4 col-md-6 mb-4 px-2px">
                    <div className="single-services">
                      <div className="services-thumb">
                        <img
                          src={fullBackendPath(
                            service.service_background?.path
                          )}
                          alt="Service"
                        />
                      </div>
                      <div className="services-content overflow-auto">
                        <h4>
                          <Link
                            to={`/service-detail/${kebabCase(service.name)}`}
                            state={{ id: service.id }}
                          >
                            {service.name}
                          </Link>
                        </h4>
                        <p>{service.short_describe}</p>
                        <Link
                          to={`/service-detail/${kebabCase(service.name)}`}
                          state={{ id: service.id }}
                        >
                          Read More <i className="fal fa-long-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section
          className="feature-area2 p-relative fix"
          style={{ background: '#f7f5f1' }}
        >
          {/* <div className="animations-02">
            <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05" />
          </div> */}
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                <div className="feature-img">
                  <img
                    src={fullBackendPath(sec3_image?.path)}
                    alt="img"
                    className="Section 3"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 py-5">
                <div className="feature-content s-about-content">
                  <div className="feature-title pb-20">
                    <p
                      dangerouslySetInnerHTML={{ __html: section3Describe }}
                    ></p>
                  </div>
                  <div className="slider-btn mt-15">
                    <Link to="/about" className="btn ss-btn smoth-scroll">
                      Discover More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="booking pt-120 pb-120 p-relative fix">
          {/* <div className="animations-01">
            <img src="assets/img/bg/an-img-01.png" alt="an-img-01" />
          </div> */}
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="contact-bg02">
                  <div className="section-title center-align">
                    <h5>make appointment</h5>
                    <h2>Book A Room</h2>
                  </div>
                  <div className="contact-form mt-30">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-name mb-20">
                          <label>
                            <i className="fal fa-badge-check"></i> Check In Date
                          </label>
                          <input type="date" id="checkin" name="date" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <label>
                            <i className="fal fa-times-octagon"></i> Check Out
                            Date
                          </label>
                          <input type="date" id="checkout" name="date" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-subject mb-20">
                          <label>
                            <i className="fal fa-users"></i> Adults
                          </label>
                          <select name="adults" id="adults">
                            <option value="">Adults</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-option mb-20">
                          <label>
                            <i className="fal fa-baby"></i> Children
                          </label>
                          <select name="children" id="children">
                            <option value="">Children</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="slider-btn mt-15">
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            onClick={onClickCheckAvailability}
                          >
                            <span>Book Table Now</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="booking-img">
                  <img src="assets/img/bg/booking-img.jpg" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="testimonial-area pt-120 pb-200 p-relative fix"
          style={{
            backgroundImage: `url(${BGTestimonial})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title center-align mb-50 text-center">
                  <h5>Testimonial</h5>
                  <h2>What Our Clients Says</h2>
                  <p>
                    Proin consectetur non dolor vitae pulvinar. Pellentesque
                    sollicitudin dolor eget neque viverra, sed interdum metus
                    interdum. Cras lobortis pulvinar dolor, sit amet ullamcorper
                    dolor iaculis vel
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <Slider className="testimonial-active" {...testimonial}>
                  {testimonialData}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  intro_section: state.home.intro_section,
  section3: state.home.section3,
  comments: state.home.comments,
  services: state.home.services,
});

const mapDispatchToProps = () => ({});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <Services {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
