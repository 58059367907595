import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { HomeActions } from '@adapters/redux/reducer/home';
import PageLoader from '@components/Loader/PageLoader';
import { convertHexToRGBA, fullBackendPath, kebabCase } from '@core/helpers';
import ErrorBoundary from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

const DestinationDetail = (props) => {
  const { t, i18n } = useTranslation();
  const { destinations, destinationDetail, getPageDetail, loading } = props;

  const { state } = useLocation();
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.id) {
      getPageDetail({
        url: `destination/${state.id}`,
        page: 'destinationDetail',
      });
    } else {
      const destination = destinations?.find((des) => {
        const kbName = kebabCase(des.name);
        return name === kbName;
      });
      if (destination) {
        getPageDetail({
          url: `destination/${destination.id}`,
          page: 'destinationDetail',
        });
      } else if (destinations?.[0]) {
        getPageDetail({
          url: `destination/${destinations[0].id}`,
          page: 'destinationDetail',
        });
      } else {
        navigate('/page-not-found', { replace: true });
      }
    }
  }, [state, name]);

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            background: `url("${fullBackendPath(
              destinationDetail?.des_background?.path
            )}")`,
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title breadcrumb-title-white">
                    <h2>
                      {i18n.language === 'en'
                        ? destinationDetail?.name || destinationDetail?.name_vi
                        : destinationDetail?.name_vi || destinationDetail?.name}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="about-area about-p pt-20 pb-20 p-relative fix"
          style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
        >
          <div className="container">
            <div
              className="about-content s-about-content wow fadeInRight animated"
              data-animation="fadeInRight"
              data-delay=".4s"
            >
              <div
                className="ck-content"
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === 'en'
                      ? destinationDetail?.describe ||
                        destinationDetail?.describe_vi
                      : destinationDetail?.describe_vi ||
                        destinationDetail?.describe,
                }}
              />
              {destinationDetail?.embed_map && (
                <iframe
                  src={destinationDetail.embed_map}
                  width="100%"
                  height={350}
                  style={{ marginTop: 20 }}
                  allowFullScreen=""
                  loading={'lazy'}
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.home.loadingPageDetail,
  destinations: state.home.destinations,
  destinationDetail: state.home.destinationDetail,
});

const mapDispatchToProps = (dispatch) => ({
  getPageDetail: (params) => dispatch(HomeActions.getPageDetail(params)),
});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <DestinationDetail {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
