import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './ckeditor.css';
import './admin-style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@framework/redux';
import { HomeActions } from '@adapters/redux/reducer/home';
import GlobalLoader from '@components/Loader/GlobalLoader';
import '@core/i18n';

const container = document.getElementById('root');
const root = createRoot(container);

store.dispatch(HomeActions.getPagesData());

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<GlobalLoader />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    <div className="may-background" />
  </React.StrictMode>
);

reportWebVitals();
