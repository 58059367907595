export const kebabCase = (str) =>
  str
    ?.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )
    ?.join('-')
    ?.toLowerCase() || '';

export const getRoomFeatureClass = (id) => {
  switch (id) {
    case 1:
      return 'fal fa-tv-retro';
    case 2:
      return 'fal fa-wifi';
    case 3:
      return 'fal fa-air-conditioner';
    case 4:
      return 'fal fa-dumpster-fire';
    case 5:
      return 'fal fa-phone-rotary';
    case 6:
      return 'fal fa-dryer-alt';
  }
};

export const getRoomFeatureName = (id) => {
  switch (id) {
    case 1:
      return 'TV';
    case 2:
      return 'Free Wifi';
    case 3:
      return 'Air Conditioner';
    case 4:
      return 'Heater';
    case 5:
      return 'Phone';
    case 6:
      return 'Laundry';
  }
};

export const fullBackendPath = (path) => {
  return process.env.BASE_URL + path;
};

export const bookNow = () => {
  const bookLink = `https://book-directonline.com/properties/anhillboutiquedirect?locale=en&items[0][adults]=0&items[0][children]=0&items[0][infants]=0&currency=VND&checkInDate=&checkOutDate=`;
  window.open(bookLink, '_blank').focus();
};

export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};
