import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { HomeActions } from '@adapters/redux/reducer/home';
import PageLoader from '@components/Loader/PageLoader';
import ErrorBoundary from '@components/ErrorBoundary';
import { fullBackendPath, getRoomFeatureClass, kebabCase } from '@core/helpers';
import { BED_TYPES, ROOM_FEATURES } from '@core/services/constant';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const RoomDetail = (props) => {
  const { t, i18n } = useTranslation();
  const { getPageDetail, loading, roomDetail, rooms } = props;

  const {
    name,
    name_vi,
    describe,
    describe_vi,
    room_feature,
    price,
    room_size,
    bed_type,
    adults,
    room_background,
    room_image1,
    room_image2,
    room_image3,
  } = roomDetail;

  const { state } = useLocation();
  const { name: nameParams } = useParams();
  const navigate = useNavigate();

  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);

  useEffect(() => {
    if (state?.id) {
      getPageDetail({ url: `room/${state.id}`, page: 'roomDetail' });
    } else {
      const room = rooms?.find((des) => {
        const kbName = kebabCase(des.name);
        return nameParams === kbName;
      });
      if (room) {
        getPageDetail({
          url: `room/${room.id}`,
          page: 'roomDetail',
        });
      } else if (rooms?.[0]) {
        getPageDetail({
          url: `room/${rooms[0].id}`,
          page: 'roomDetail',
        });
      } else {
        navigate('/page-not-found', { replace: true });
      }
    }
  }, [state, nameParams]);

  const onClickCheckAvailability = () => {
    const checkin = moment(checkInDate || new Date()).format('DD/MM/YYYY');
    const checkout = moment(checkOutDate || new Date()).format('DD/MM/YYYY');
    const adults = document.getElementById('adults').value;
    const children = document.getElementById('children').value;
    const bookLink = `https://book-directonline.com/properties/anhillboutiquedirect?locale=en&items[0][adults]=${adults}&items[0][children]=${children}&currency=VND&checkInDate=${checkin}&checkOutDate=${checkout}`;
    window.open(bookLink, '_blank').focus();
  };

  const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
    <div className="custom-input-date" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            background: `url("${fullBackendPath(room_background?.path)}")`,
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title breadcrumb-title-white">
                    <h2>
                      {i18n.language === 'en'
                        ? roomDetail?.name || roomDetail?.name_vi
                        : roomDetail?.name_vi || roomDetail?.name}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="booking" className="booking-area p-relative">
          <div className="container">
            <div className="row align-items-center contact-form">
              <div className="col-lg-12">
                <ul>
                  <li className="hidden-on-mobile">
                    <div className="contact-field p-relative c-name">
                      <label>
                        <i className="fal fa-badge-check"></i>{' '}
                        {t('Check In Date')}
                      </label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        showIcon
                        selected={checkInDate}
                        onChange={(date) => setCheckInDate(date)}
                        icon="fal fa-calendar"
                        customInput={<CustomInputDate />}
                      />
                    </div>
                  </li>
                  <li className="hidden-on-mobile">
                    <div className="contact-field p-relative c-name">
                      <label>
                        <i className="fal fa-times-octagon"></i>{' '}
                        {t('Check Out Date')}
                      </label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        showIcon
                        selected={checkOutDate}
                        onChange={(date) => setCheckOutDate(date)}
                        icon="fal fa-calendar"
                        customInput={<CustomInputDate />}
                      />
                    </div>
                  </li>
                  <li className="hidden-on-mobile">
                    <div className="contact-field p-relative c-name">
                      <label>
                        <i className="fal fa-users"></i> {t('Adults')}
                      </label>
                      <select name="adults" id="adults">
                        <option value="">{t('Adults')}</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </li>
                  <li className="hidden-on-mobile">
                    <div className="contact-field p-relative c-name">
                      <label>
                        <i className="fal fa-baby"></i> {t('Children')}
                      </label>
                      <select name="children" id="children">
                        <option value="">{t('Children')}</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </li>
                  <li className="button-check-availability">
                    <div className="slider-btn">
                      <label>&nbsp;</label>
                      <button
                        className="btn ss-btn"
                        data-animation="fadeInRight"
                        data-delay=".8s"
                        onClick={onClickCheckAvailability}
                        style={{ zIndex: 'unset' }}
                      >
                        {t('Check Availability')}
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="about-area5 about-p p-relative">
          {loading ? (
            <PageLoader />
          ) : (
            <div className="container pt-120 pb-40">
              <div className="row">
                <div className="col-lg-7 col-md-12 col-sm-12 px-2px">
                  <div className="service-detail">
                    <div className="two-column">
                      <div className="row">
                        <div className="image-column room-detail-image-1 col-xl-6 col-lg-12 col-md-12 px-2px">
                          <figure className="image">
                            <img
                              src={fullBackendPath(room_image1?.path)}
                              alt=""
                            />
                          </figure>
                        </div>
                        <div className="text-column room-detail-image-2-3 col-xl-6 col-lg-12 col-md-12 px-2px">
                          <figure className="image">
                            <img
                              src={fullBackendPath(room_image2?.path)}
                              alt=""
                            />
                          </figure>
                          <figure className="image">
                            <img
                              src={fullBackendPath(room_image3?.path)}
                              alt=""
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-5 pl-30">
                  <div className="content-box">
                    <h2>
                      {i18n.language === 'en'
                        ? name || name_vi
                        : name_vi || name}
                    </h2>
                    <div
                      className="ck-content"
                      dangerouslySetInnerHTML={{
                        __html:
                          i18n.language === 'en'
                            ? describe || describe_vi
                            : describe_vi || describe,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.home.loadingPageDetail,
  roomDetail: state.home.roomDetail,
  rooms: state.home.rooms,
});

const mapDispatchToProps = (dispatch) => ({
  getPageDetail: (params) => dispatch(HomeActions.getPageDetail(params)),
});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <RoomDetail {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
