import React from 'react';
import './index.scss';
import Logo from '@assets/img/logo/logo.png';

const PageLoader = () => {
  return (
    <div className="page-loader-container">
      <div className="page-loader">
        <img src={Logo} alt="logo" />
      </div>
    </div>
  );
};

export default PageLoader;
