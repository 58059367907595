import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { connect } from 'react-redux';
import {
  bookNow,
  convertHexToRGBA,
  fullBackendPath,
  getRoomFeatureClass,
  kebabCase,
} from '@core/helpers';
import ErrorBoundary from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import RoomBG from '../../../assets/img/bg/room_bg.jpg';

const OurRooms = (props) => {
  const { t, i18n } = useTranslation();
  const { rooms } = props;

  const onClickCheckAvailability = () => {
    const checkin = document.getElementById('checkin').value;
    const checkout = document.getElementById('checkout').value;
    const children = document.getElementById('children').value;
    const bookLink = `https://book-directonline.com/properties/anhillboutiquedirect?locale=en&items[0][children]=${children}&items[0][infants]=0&currency=VND&checkInDate=${checkin}&checkOutDate=${checkout}`;
    window.open(bookLink, '_blank').focus();
  };

  return (
    <main>
      <section
        className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
        style={{
          background: `url("${RoomBG}")`,
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-center">
                <div className="breadcrumb-title">
                  <h2>{t('Rooms & Suites')}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="services"
        className="services-area pt-90 pb-90"
        style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
      >
        <div className="container">
          <div className="row justify-content-center">
            {rooms.map((room, index) => {
              if (index === 0)
                return null

              const {
                id,
                room_background,
                price,
                name,
                name_vi,
                short_describe,
                short_describe_vi,
                room_feature,
              } = room;

              return (
                <div key={id} className="col-xl-4 col-md-6 mb-4 px-2px">
                  <Link
                    to={`/room-detail/${kebabCase(name)}`}
                    state={{ id }}
                    className="gallery-link popup-image"
                  >
                    <div className="single-services position-relative">
                      <div className="services-thumb">
                        <img
                          src={fullBackendPath(room_background?.path)}
                          alt="img"
                        />
                      </div>
                      <div className="services-thumb-hover">
                        <div className="border-1">
                          <div className="border-2 text-center">
                            {i18n.language === 'en'
                              ? name || name_vi
                              : name_vi || name}
                          </div>
                        </div>
                      </div>
                      {/* <div className="services-content">
                        <div className="day-book">
                          <ul>
                            <li>
                              <Link to="#" onClick={() => bookNow()}>
                                {t('Book Now')}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <h4>
                          <Link
                            to={`/room-detail/${kebabCase(name)}`}
                            state={{ id }}
                          >
                            {name}
                          </Link>
                        </h4>
                        <p style={{ maxHeight: 245 }}>
                          {i18n.language === 'en'
                            ? short_describe || short_describe_vi
                            : short_describe_vi || short_describe}
                        </p>
                        <div className="icon">
                          <ul
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexWrap: 'wrap',
                            }}
                          >
                            {room_feature?.map((feature) => {
                              return (
                                <li
                                  style={{
                                    marginRight: 0,
                                  }}
                                >
                                  <i
                                    style={{ fontSize: 23 }}
                                    className={getRoomFeatureClass(feature)}
                                  ></i>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = (state) => ({
  rooms: state.home.rooms,
});

const mapDispatchToProps = () => ({});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <OurRooms {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
