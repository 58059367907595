import ZaloFullSize from '@components/Icons/zaloFullSize';
import { kebabCase } from '@core/helpers';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Logo from '@assets/img/logo/logo-name-white.png';
import ErrorBoundary from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

const Navbar = (props) => {
  const { t, i18n } = useTranslation();
  const { ownerInfo, rooms, services, offers, destinations } = props;
  const { phone1, phone2, working_hour, working_hour_vi, socials } = ownerInfo;

  const facebookInfo = socials.find((s) => s.name === 'facebook');
  const zaloInfo = socials.find((s) => s.name === 'zalo');
  const instagramInfo = socials.find((s) => s.name === 'instagram');
  const youtubeInfo = socials.find((s) => s.name === 'youtube');

  const [mobile, setMobile] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  const changeCurrentLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('vi');
      localStorage.setItem('language', 'vi');
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
    }
  };

  return (
    <>
      <header className="header-area header-three">
        <div id="header-sticky" className="menu-area">
          <div className="container-fluid">
            <div className="second-menu">
              <div className="row align-items-center position-relative">
                <div className="col-md-2 col-xl-2 col-lg-2">
                  <div className="logo">
                    <Link to="/">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8 col-lg-8 px-3">
                  <div className="main-menu text-center">
                    <nav id="mobile-menu">
                      <ul>
                        <li>
                          <Link to="/">{t('Home')}</Link>
                        </li>
                        <li>
                          <Link to="/rooms">{t('Rooms & Suites')}</Link>
                        </li>
                        <li>
                          <Link to="/dining">{t('Dining')}</Link>
                        </li>
                        <li>
                          <Link to="/spa-wellness">{t('Spa & Wellness')}</Link>
                        </li>
                        <li>
                          <Link to="/experiences">{t('Experiences')}</Link>
                        </li>
                        <li>
                          <Link to="/offer-detail/festive-indulgence-at-the-hill">{t('Offers')}</Link>
                        </li>
                        <li>
                          <Link to="/events">{t('Events & Celebrations')}</Link>
                        </li>
                        <li>
                          <Link to="/destinations">{t('Destinations')}</Link>
                        </li>
                        <li>
                          <Link to="/gallery">{t('Gallery')}</Link>
                        </li>
                        <li>
                          <Link to="/contact">{t('Contact')}</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2 d-none d-lg-block">
                  <div className="d-flex justify-content-end">
                    <span
                      className="mt-10 mb-10 choose-language"
                      onClick={changeCurrentLanguage}
                    >
                      {i18n.language === 'en' ? 'VIE' : 'ENG'}
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <div className="language-mobile">
                        <span
                          className="mt-10 mb-10 choose-language"
                          onClick={changeCurrentLanguage}
                        >
                          {i18n.language === 'en' ? 'VIE' : 'ENG'}
                        </span>
                      </div>
                      <Link
                        className={`meanmenu-reveal ${mobile && 'meanclose'}`}
                        onClick={() => setMobile(!mobile)}
                        style={{
                          right: '0px',
                          left: 'auto',
                          top: 5,
                          textAlign: 'center',
                          textIndent: '0px',
                          fontSize: '18px',
                        }}
                      >
                        {mobile ? (
                          'X'
                        ) : (
                          <>
                            <span>
                              <span>
                                <span></span>
                              </span>
                            </span>
                          </>
                        )}
                      </Link>
                      <nav className="mean-nav">
                        {mobile && (
                          <ul style={{ display: mobile ? 'block' : 'none' }}>
                            <li>
                              <Link to="/" onClick={() => setMobile(false)}>
                                {t('Home')}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/rooms"
                                onClick={() => setMobile(false)}
                              >
                                {t('Rooms & Suites')}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/dining"
                                onClick={() => setMobile(false)}
                              >
                                {t('Dining')}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/spa-wellness"
                                onClick={() => setMobile(false)}
                              >
                                {t('Spa & Wellness')}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/experiences"
                                onClick={() => setMobile(false)}
                              >
                                {t('Experiences')}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/offers"
                                onClick={() => setMobile(false)}
                              >
                                {t('Offers')}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/events"
                                onClick={() => setMobile(false)}
                              >
                                {t('Events & Celebrations')}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/destinations"
                                onClick={() => setMobile(false)}
                              >
                                {t('Destinations')}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/gallery"
                                onClick={() => setMobile(false)}
                              >
                                {t('Gallery')}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/contact"
                                onClick={() => setMobile(false)}
                              >
                                {t('Contact')}
                              </Link>
                            </li>
                          </ul>
                        )}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  ownerInfo: state.home.ownerInfo,
  rooms: state.home.rooms,
  services: state.home.services,
  destinations: state.home.destinations,
  offers: state.home.offers,
});

const mapDispatchToProps = () => ({});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <Navbar {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
