import React from 'react';
import './index.scss';
import Logo from '@assets/img/logo/logo.png';

const GlobalLoader = () => {
  return (
    <div className="global-loader">
      <img src={Logo} alt="logo" />
    </div>
  );
};

export default GlobalLoader;
