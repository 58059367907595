import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { HomeActions } from '@adapters/redux/reducer/home';
import PageLoader from '@components/Loader/PageLoader';
import { convertHexToRGBA, fullBackendPath, kebabCase } from '@core/helpers';
import ErrorBoundary from '@components/ErrorBoundary';
import { useTranslation } from 'react-i18next';

const ServiceDetail = (props) => {
  const { t, i18n } = useTranslation();
  const {
    intro_section,
    services,
    serviceDetail,
    getPageDetail,
    loading,
    ownerInfo,
  } = props;
  const { phone1, phone2 } = ownerInfo;
  const { intro_image1 } = intro_section;

  const { state } = useLocation();
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.id) {
      getPageDetail({ url: `service/${state.id}`, page: 'serviceDetail' });
    } else {
      const service = services?.[0];
      if (service) {
        getPageDetail({ url: `service/${service.id}`, page: 'serviceDetail' });
      } else {
        navigate('/page-not-found', { replace: true });
      }
    }
  }, [state, name]);

  const getActiveItem = (serviceID, index) => {
    if (!state?.id) {
      if (index === 0) return 'active';
      return '';
    }
    return serviceID === state?.id ? 'active' : '';
  };

  return (
    <>
      <main>
        <section
          className="breadcrumb-area breadcrumb-area-detail d-flex align-items-center background-header"
          style={{
            background: `url("${fullBackendPath(intro_image1?.path)}")`,
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="breadcrumb-wrap text-center">
                  <div className="breadcrumb-title">
                    <h2>Service Detail</h2>
                    {/* <div className="breadcrumb-wrap">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Service Detail
                          </li>
                        </ol>
                      </nav>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="about-area5 about-p">
          <div
            className="container pt-90 pb-90"
            style={{ background: convertHexToRGBA('#ffffff', 0.8) }}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 order-1">
                <aside className="sidebar services-sidebar">
                  <div className="sidebar-widget categories">
                    <div className="widget-content">
                      <h2 className="widget-title">Services List</h2>
                      <ul className="services-categories">
                        {services?.map((service, index) => {
                          return (
                            <li
                              key={service.id}
                              className={getActiveItem(service.id, index)}
                            >
                              <Link
                                to={`/service-detail/${kebabCase(
                                  service.name
                                )}`}
                                state={{ id: service.id }}
                              >
                                {service.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="service-detail-contact wow fadeup-animation"
                    data-wow-delay="1.1s"
                  >
                    <h3 className="h3-title">
                      If You Need Any Help Contact With Us
                    </h3>
                    <Link to="#" title="Call now">
                      {phone1}
                    </Link>
                    <br />
                    <br />
                    <Link to="#" title="Call now">
                      {phone2}
                    </Link>
                  </div>
                </aside>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 order-2 p-relative">
                <div className="service-detail">
                  <div className="content-box">
                    {loading ? (
                      <PageLoader />
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: serviceDetail?.describe,
                        }}
                      ></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.home.loadingPageDetail,
  intro_section: state.home.intro_section,
  services: state.home.services,
  serviceDetail: state.home.serviceDetail,
  ownerInfo: state.home.ownerInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getPageDetail: (params) => dispatch(HomeActions.getPageDetail(params)),
});

const withErrorBoundary = (props) => (
  <ErrorBoundary>
    <ServiceDetail {...props} />
  </ErrorBoundary>
);

export default connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary);
